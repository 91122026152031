<script setup>

const config = useRuntimeConfig()
definePageMeta({
  layout: "spa",
});

const { version } = useZigZag()
const { data, refresh, error } = await version(config.public.zigzagUrl)

const time = data.value ? data.value.t : 0;
const sha = data.value ? data.value.sha : 'versionnotfound';

const url = `${config.public.zigzagUrl}/app/layout/shell.html?sha=${sha}&t=${time}`;
const loaded = ref(false)


const route = useRoute();
const backButtonClicked = ref(false);
const stateUpdated = ref(false)
const routes = useRoutes();
const historyLength = ref(0)


watch(() => route.path, () => {
  if (route.fullPath.includes('#/dispatcher') && backButtonClicked.value && !route.fullPath.includes('#/dispatcheraccount')) {
    const length = routes.length;
    const index = routes.getReferrer;
    const list = routes.getList()
    const referrer = structuredClone(list[index - 1])
    routes.clean();
    window.location.href = referrer;
  }
  
}, {immediate: true, deep: true})


onMounted(() => {

  window.addEventListener('popstate', (event) => { 
    stateUpdated.value = true;
    if (historyLength.value === history.length) {
      backButtonClicked.value = true;
    }
    historyLength.value = history.length;
  });

})

const path = route.fullPath.includes('#') ? route.fullPath : '/splash'

</script>

<template>

  <div id="www-spa-container"  class="www-pulseapp position-relative" :data-page="path" >
    
      <div class="pulseapp">
        <AngularSpinner />
        <div class="shell-container" id="shell" :ng-include="`'${url}'`">
          <div class="box-wrapper">
            
          </div>
        </div>
      </div>
    
  </div>
</template>

<style lang="scss" scoped>
#www-spa-container {
  padding-top: 90px;
  padding-bottom: 40px;
  .pulseapp {
    overflow: auto;
    min-height: 100px;
  }
}
</style>